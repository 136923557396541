import React, { useEffect, useState } from "react";
import apiClient from "../api";
import { Button, Spinner } from "react-bootstrap";

export default function Keunggulan() {
  const [advantages, setAdvantages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hero, setHero] = useState([]);

  useEffect(() => {
    const fetchAdvantages = async () => {
      try {
        const response = await apiClient.get("advantages");
        setAdvantages(response.data);
        // setLoading(false);
      } catch (err) {
        setError(err.message);
        // setLoading(false);
      }
    };

    fetchAdvantages();
  }, []);

  useEffect(() => {
    const fetchHero = async () => {
      try {
        const response = await apiClient.get("ContentImages");
        setHero(response.data);
        // setLoading(false);
      } catch (err) {
        setError(err.message);
        // setLoading(false);
      }
    };

    fetchHero();
  }, []);

const fotokeunggulan = hero.filter((item)=>item.bagian==='keunggulan');

// console.log(fotokeunggulan);
  return (
    <section className="keunggulan">
      <div className="container">
        <div className="row flex-column-reverse flex-lg-row">
        {/* {loading ? (
              <div className="spinner-container">
                <Spinner animation="border" />
              </div>
            ) : error ? (
              <p>Error: {error}</p>
            ) : (
              <> */}
          <div className="col-lg-6">
            <img src={fotokeunggulan[0]?.image} alt="keunggulan" />
          </div>
          <div className="col-lg-6">
           
                <div>
                  <h1>
                    Mengapa Memilih <br /> Mirai Akademi
                  </h1>
                  <table className="keuntungan">
                    {advantages.map((advantage) => (
                      <tr>
                        <td>
                          <img
                            src={advantage.image}
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                            }}
                            alt=""
                          />
                        </td>
                        <td className="deskripsi-misi">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: advantage.content,
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </table>

                  <a href="https://bit.ly/PendaftaranLPKMiraiAkademi">
                    <Button className="btn btn-orange mt-5">JOIN NOW</Button>
                  </a>
                </div>
             
          </div>
          {/* </>
            )} */}
        </div>
      </div>
    </section>
  );
}
