import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Syarat from "./syarat";
import Cta from "./cta";
import { Helmet } from "react-helmet-async";
import Footer from "./footer";

export default function ProgramDetail1() {

  return (
    <div className="page-home">        
      <Helmet>
          PROGRAM | TOKUTEI GINOU
      </Helmet>

        <Cta />
    
      <section className="bg-light py-5">
        <div className="container" >
          <div className="row" >
            <div className="col-lg-6">
              <h1 className="text-center mb-4">Tokutei Ginou</h1>
              <p className="text-center mb-4">
              LPK Mirai Akademi memiliki program Tokutei Ginou yang merupakan Visa kerja keahlian khusus bagi warga negara asing yang ingin bekerja di Jepang.
              </p>
              <p className="text-center mb-4">
                Pemilik visa tersebut memungkinkan mereka untuk bekerja di
                perusahaan Jepang dengan hak dan kewajiban yang sama dengan
                pekerja Jepang.
              </p>
              <h3 className="text-center mb-3">Benefits</h3>
              <ul className="list-unstyled text-left">
                <li className="list-group-item"><FontAwesomeIcon icon={faCircleCheck} className="mr-2"/>Masa Kontrak Lebih Panjang</li>
                <li className="list-group-item"><FontAwesomeIcon icon={faCircleCheck} className="mr-2"/>Batas Usia Yang Lebih Luas</li>
                <li className="list-group-item"><FontAwesomeIcon icon={faCircleCheck} className="mr-2"/>
                  Kesempatan Memiliki Visa Permanen Residen
                </li>
                <li className="list-group-item"><FontAwesomeIcon icon={faCircleCheck} className="mr-2"/>Peluang Kerja di Berbagai Sektor</li>
                <li className="list-group-item"><FontAwesomeIcon icon={faCircleCheck} className="mr-2"/>Masa Tinggal yang Lama</li>
                <li className="list-group-item"><FontAwesomeIcon icon={faCircleCheck} className="mr-2"/>Pengembangan Keterampilan dan Pengalaman</li>
              </ul>
              <div>
                <h5><a href="/program2">Program Kuliah Sambil Kerja</a></h5>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img
                src="/images/tokuteiginou.jpg"
                alt="Tokutei Ginou"
                className="img-fluid "
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6  d-flex justify-content-center">
            <a href="https://bit.ly/PendaftaranLPKMiraiAkademi" target='_blank'>
              <button className="btn btn-primary mt-2 btn-lg">JOIN NOW</button></a>
            </div>
          </div>
        </div>
        
      </section>
      
        <div class="container">
        <section className="section-gallery">
        
        <Syarat />
        
        
      </section>
      </div>
      <Footer />
    </div>
  );
}


