import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Syarat from "./syarat";
import { Helmet } from "react-helmet-async";
import Cta from "./cta";
import Footer from "./footer";

export default function ProgramDetail2() {

  return (
    <div className="page-home">
         <Helmet>
          PROGRAM | KULIAH SAMBIL KERJA
      </Helmet>

        <Cta />
      <section className="bg-light py-5">        

        <div className="container mt-5" id="program2">
          <div className="row">
            <div className="col-lg-6 d-flex justify-content-center mt-5">
              <img
                src="/images/kuliahsambilkerja.jpg"
                alt="Kuliah Sambil Kerja"
                className="img-fluid "
                
              />
            </div>
            <div className="col-lg-6">
              <h1 className="text-center mb-4">Kuliah Sambil Kerja</h1>
              <p className="text-center mb-4">
                Tokutei Ginou merupakan visa kerja keahlian khusus bagi warga negara
                asing yang ingin bekerja di Jepang. Pemilik visa tersebut memungkinkan
                mereka untuk bekerja di perusahaan Jepang dengan hak dan kewajiban
                yang sama dengan pekerja Jepang.
              </p>

              <h3 className="text-center mb-3">Benefits</h3>
              <ul className="list-unstyled text-right">
                <li >
                  Pasti Kerja Sambil Kuliah
                  <FontAwesomeIcon icon={faCircleCheck} className="ml-2"/>
                </li>
                <li >
                  Lulus Sarjana Dapat Ratusan Juta Rupiah
                  <FontAwesomeIcon icon={faCircleCheck} className="ml-2"/>
                </li>
                <li >
                  Pengalaman Kerja Internasional
                  <FontAwesomeIcon icon={faCircleCheck} className="ml-2"/>
                </li>
                <li >
                  Bayar Kuliah Setelah Gajian
                  <FontAwesomeIcon icon={faCircleCheck} className="ml-2"/>
                </li>
              </ul>
              <div>
                <h5 className="text-right"><a href="/program1">Tokutei Ginou</a></h5>
              </div>
            </div>
           
          </div>
            
          <div className="row d-flex justify-content-end">
            <div className="col-md-6  d-flex justify-content-center">
            <a href="https://bit.ly/PendaftaranLPKMiraiAkademi" target='_blank'>
              <button className="btn btn-primary mt-2 btn-lg">JOIN NOW</button></a>
            </div>
          </div>
        </div>
      </section>
      <div class="container">
        <section className="section-gallery">
            <Syarat />
            </section>
      </div>
      <Footer />
    </div>
  );
}


