import React from 'react';

import Hero from './hero';
import Profile from './profile';
import Visi from './visi';
import Program from './programbaru';
import Keunggulan from './keunggulan';
import Gallery from './gallery';
import Berita from './Berita';
import Testimoni from './testimoni';
import Footer from './footer';
import Cta from './cta';
import { Helmet } from 'react-helmet-async';
import ProgramDetail from './programDetail1';

export default function Welcome() {
    return (
        <div className="page-home">
        <Helmet>
        <title>Welcome | Mirai Akademi</title>
        <meta name="description" content="Mirai Akademi" />
        <meta name='author' content='PT. Mitra Bisnis Cipta Karya' />
        <meta name='keywords' content='mirai akademi, lpk, kerja ke jepang' />
        </Helmet>
            <Hero />
            <Cta />
            <Profile />
            <Visi />
            <section className="melengkung"></section>
            <Program />
            {/* <ProgramDetail /> */}
            <Keunggulan />
            <Gallery />
            <section className="melengkung"></section>
            <Berita istampil={true}/>
            <Testimoni />

            <Cta />
            <Footer />

        </div>
    );
}
