export default function Cta() {
    return (
        <section className="cta">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-auto mr-auto">
                        <h5>Bring your career on the right track.</h5>
                        <h1>
                            Start today!
                        </h1>
                    </div>
                    <div className="col-auto">
                        <a href="https://bit.ly/PendaftaranLPKMiraiAkademi" target="_blank"><button type="button" className="btn btn-orange2">JOIN NOW</button></a>
                    </div>
                </div>
            </div>
        </section>
    )
}