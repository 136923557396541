import { Link } from "react-router-dom"
import apiClient from "../api"
import React,  { useState , useEffect} from "react";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";


export default function Footer() {

    const [sosmed, setSosmed] = useState([]);
    const [email, setEmail] = useState([]);
    const [dataAlamat, setAlamat] = useState([]);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      
      fetchPrograms();
      fetchAlamat();
      fetchEmail();
    }, []);

    const fetchPrograms = async () => {
        try {
          const response = await apiClient.get("kontak?category=sosmed");
          setSosmed(response.data);
        //   setLoading(false);
        } catch (err) {
          setError(err.message);
        //   setLoading(false);
        }
      };
  
    const fetchAlamat = async () => {
        try {
          const response = await apiClient.get("kontak?category=alamat");
          setAlamat(response.data);
        //   setLoading(false);
        } catch (err) {
          setError(err.message);
        //   setLoading(false);
        }
      };
    const fetchEmail = async () => {
        try {
          const response = await apiClient.get("kontak?category=email");
          setEmail(response.data);
        //   setLoading(false);
        } catch (err) {
          setError(err.message);
        //   setLoading(false);
        }
      };
  
// console.log(dataAlamat);
    return (
        <section className="footer" id="kontak">
            <div className="container mx-auto position-relative">
                <div className="row gap-md-0 gap-3 pb-5">
                    <div className="col-lg-5 col-md-12">
                        <div>
                            <div className="logo-footer">
                                <img src="/images/logo.png" alt="" />
                            </div>
                            <div className="text-footer">
                                Mirai Akademi resmi terdaftar di Indonesia sejak Tahun 2023, pendiri Perusahaan kami telah lama memiliki usaha di bidang penyaluran tenaga kerja ke Jepang dan telah memiliki banyak Perusahaan rekanan di Jepang.
                                <br />Jl. Janger No. 1, Dauh Yeh Cani, Kec. Abiansemal, Kab. Badung, Bali - Indonesia
                            </div>
                            <div className="d-flex sosmed align-items-center pt-3">
                                {sosmed.map((item)=>(
                                    <a href={item.link} target="blank" className="mr-2">
                                        <img src={item.image} alt={item.title} />
                                    </a>
                                ))}
                              
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                        <h2 className="footer-text-title">Kontak Kami</h2>
                        <table border="0">
                            {
                                dataAlamat.map((item)=>(
                                    <tr key={item.id}>
                                        <td><a href={item.link} target="_blank"><img src={item.image} style={{width:'50px'}}/></a></td>
                                        <td>{item.title}</td>
                                    </tr>
                                ))
                            }
                            <tr>
                                <td><img src={email[0]?.image} style={{width:'60px'}}/></td>
                                <td>
                                {                                    
                                    email.map((item)=>(
                                        <p><a href={`mailto:${item.link}`}>{item.link}</a></p>
                                    ))
                                }
                                </td>
                            </tr>
                          
                        </table>
                        

                        <div className="copyright">
                            <p>Copyright © 2024 | Mirai Akademi</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <h2 className="footer-text-title">Program</h2>
                        <nav className="list-unstyled">
                            <li className="list-space mt-2">

                                <a href="/#programx" className="list-menu">Tokutei Ginou</a>
                            </li>
                           
                        </nav>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <h2 className="footer-text-title">Akses Cepat</h2>
                        <nav className="list-unstyled">
                            <li className="list-space mt-2">
                                <a href="/#profil" className="list-menu">Profil</a>
                            </li>

                            <li className="list-space mt-2">
                                <a href="/berita" className="list-menu">Berita</a>
                            </li>
                            <li className="list-space mt-2">
                                <a href="/#testimoni" className="list-menu">Testimoni</a>
                            </li>

                        </nav>
                    </div>

                </div>
                <div className="info-footer">
                    <div className="d-flex justify-content-between">
                        <div></div>
                        <div className="floatwa">
                            <div className="d-inline">
                                <button className="btn-wa p-2" type="button">
                                    <a href="https://api.whatsapp.com/send?phone=6281371900814&amp;text=Halo,%20Saya%20ingin%20mengetahui%20informasi%20tentang%20pendaftaran" target="_blank">
                                        <img src="/images/whatsapp.png" alt="" />
                                        <span>Chat Kami</span>
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}