import React, { useEffect, useState } from "react";
import apiClient from "../api";
import { Button, Spinner } from "react-bootstrap";

export default function Berita({ istampil }) {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const calculateTimeAgo = (updatedAt) => {
    const currentDate = new Date();
    const updatedDate = new Date(updatedAt);
    const timeDifference = currentDate - updatedDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return 'Baru saja';
    } else if (minutes === 1) {
      return '1 menit yang lalu';
    } else if (minutes < 60) {
      return `${minutes} menit yang lalu`;
    } else if (hours === 1) {
      return '1 jam yang lalu';
    } else if (hours < 24) {
      return `${hours} jam yang lalu`;
    } else if (days === 1) {
      return '1 hari yang lalu';
    } else {
      return `${days} hari yang lalu`;
    }
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await apiClient.get('blogs');
        setBlogs(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderBlogs = () => {
    const indexOfLastBlog = currentPage * itemsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - itemsPerPage;
    const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

    const chunkArray = (array, size) => {
      const chunkedArr = [];
      for (let i = 0; i < array.length; i += size) {
        chunkedArr.push(array.slice(i, i + size));
      }
      return chunkedArr;
    };

    const blogChunks = chunkArray(currentBlogs, 4);

    return blogChunks.map((chunk, index) => (
      <div className="card-deck" key={index}>
        {chunk.map((blog) => (
        <div className="col-lg-3">
          <div className="card" key={blog.id}>
            <img
              className="card-img-top"
              src={blog.image_url}
              alt="Card image cap"
              style={{ height: '200px' }}
            />
            <div className="card-body">
              <h5 className="card-title">
                <a href={`/detail-berita/${blog.id}`}>{blog.title}</a>
              </h5>
              <p className="card-text">
                <small className="text-muted">
                  Last updated {calculateTimeAgo(blog.updated_at)}
                </small>
              </p>
              <div className="card-text">
                <div dangerouslySetInnerHTML={{ __html: blog.overview }} />
                <a href={`/detail-berita/${blog.id}`}>Selengkapnya...</a>
              </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    ));
  };

  const totalPages = Math.ceil(blogs.length / itemsPerPage);

  return (
    <section className="berita" id="berita">
      <div className="container">
        {loading ? (
          <div className="spinner-container">
            <Spinner animation="border" />
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <>
            {istampil && (
              <div className="row">
                <div className="col-lg-12">
                  <h1>Berita<br />Mirai Akademi</h1>
                </div>
              </div>
            )}
            {renderBlogs()}
            <div className="pagination">
              {[...Array(totalPages)].map((_, index) => (
                <Button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  {index + 1}
                </Button>
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
}
