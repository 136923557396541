import { Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import apiClient from "../api";

export default function Hero() {

const [hero, setHero]    = useState([]);
const [error, setError]    = useState([]);

  useEffect(() => {
    const fetchHero = async () => {
      try {
        const response = await apiClient.get("heroes");
        setHero(response.data);
        // setLoading(false);
      } catch (err) {
        setError(err.message);
        // setLoading(false);
      }
    };

    fetchHero();
  }, []);

// const fotokeunggulan = hero.filter((item)=>item.bagian==='header');


    return (
        <section className="hero" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: hero?.sub_title
                          }}
                        />
                        </h5>
                        <h1>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: hero?.title
                          }}
                        />
                        </h1>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: hero?.content
                          }}
                        />
                        <a href="https://bit.ly/PendaftaranLPKMiraiAkademi"><Button variant="warning" >JOIN NOW</Button></a>
                    </div>
                    <div className="col-lg-6">
                        <img src={hero?.image} alt="Hero" />
                    </div>
                </div>
            </div>
        </section>
    );
}