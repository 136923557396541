import React from "react"; 
import { useEffect, useState } from "react";
import apiClient from "../api";

const Visi= ()=> {
const [visions, setVisions]=useState([]);
const [misi, setMisi]=useState([]);

const fetchBlogs = async () => {
    try {
      const response = await apiClient.get('visions');
      setVisions(response.data);
      // setLoading(false);
    } catch (err) {
      console.log(err);
      // setError(err.message);
      // setLoading(false);
    }
  };
  const fetchMisi = async () => {
    try {
      const response = await apiClient.get('missions');
      setMisi(response.data);
      // setLoading(false);
    } catch (err) {
      console.log(err);
      // setError(err.message);
      // setLoading(false);
    }
  };

    useEffect(() => {
       
    
        fetchBlogs();
        fetchMisi();
      }, []);

      // console.log(visions);
      // console.log(misi);

    return (
        <section className="visi" id="visi"> 
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h1>
                            Visi
                        </h1>
                         <div dangerouslySetInnerHTML={{ __html: visions[0]?.content }} />
                        
                        <h1>
                            Misi
                        </h1>
                        <table className="misi">
                            {
                                misi.map((item, index) => (
                                    <tr key={index}>
                                        <td><img src={item.image} alt="" /></td>
                                        <td className="deskripsi-misi"><div dangerouslySetInnerHTML={{ __html: item?.content }} /></td>
                                    </tr>
                                ))
                            }
                            
                            
                        </table>
                    </div>
                    <div className="col-lg-6">
                        <img src={visions[0]?.image} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Visi;