import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../api";
import Footer from "./footer";
import { Spinner } from "react-bootstrap";



const DetailGalery = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [galeri, setGaleri] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await apiClient.get('gallerys');
                setGaleri(response.data);
                setLoading(false);
            } catch (err) {
                console.log(err.message);
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="page-home">
            <section className="cta">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 text-center">

                            <h1>Galeri Kegiatan LPK Mirai Akademi</h1>
                        </div>
                    </div>
                </div>
            </section>
            {loading ? (
                <div className="spinner-container">
                    <Spinner animation="border" />
                </div>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <section className="section-gallery">

                    <div className="container">
                        <div className="gallery">
                            {galeri.map((item, index) => (
                                <a href={item.image} data-lightbox="models" data-title={item.caption}>
                                    <img src={item.image} alt={item.caption} />
                                </a>
                            ))}

                        </div>

                    </div>
                    
                </section>
            )}
            <Footer />
        </div>
    );
};

export default DetailGalery;

