import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './main.css';
import Welcome from './Components/Welcome';
import NavBar from './Components/navbar';
import News from './Components/News';
import ProgramDetail1 from './Components/programDetail1';
import ProgramDetail2 from './Components/programDetail2';

import DetailBerita from './Components/DetailBerita';
import DetailGalery from './Components/DetailGalery';

function App() {
  return (
    <>
    <HelmetProvider>
    <Router>
      <NavBar />
      
        <Routes>
          <Route path="/" element={
            <Welcome />
          } />

          <Route path="/berita" element={
              <News />            
          } />
          <Route path="/detail-berita/:id" element={
              <DetailBerita />            
          } />
          <Route path="/program1" element={
              <ProgramDetail1 />            
          } />
          <Route path="/program2" element={
              <ProgramDetail2 />            
          } />
          <Route path="/all-gallery" element={
              <DetailGalery />            
          } />

        </Routes>
        
      
    </Router>
    </HelmetProvider>
  
</>
  );
}
export default App;