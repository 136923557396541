import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../api";
import Footer from "./footer";
import { Spinner } from "react-bootstrap";
import Berita from "./Berita";


const DetailBerita = () => {
  const { id } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [berita, setBerita] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const calculateTimeAgo = (updatedAt) => {
    const currentDate = new Date();
    const updatedDate = new Date(updatedAt);
    const timeDifference = currentDate - updatedDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return 'Baru saja';
    } else if (minutes === 1) {
      return '1 menit yang lalu';
    } else if (minutes < 60) {
      return `${minutes} menit yang lalu`;
    } else if (hours === 1) {
      return '1 jam yang lalu';
    } else if (hours < 24) {
      return `${hours} jam yang lalu`;
    } else if (days === 1) {
      return '1 hari yang lalu';
    } else {
      return `${days} hari yang lalu`;
    }
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await apiClient.get(`blogs/${id}`);
        setBlogs(response.data.blogs);
        setBerita(response.data.berita);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="page-berita">
      <section className="cta">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center">
              <h5>{formatDate(berita.updated_at)} | Kategori:  {berita.category}</h5>
              <h1>{berita.title}</h1>
            </div>
          </div>
        </div>
      </section>
      {loading ? (
          <div className="spinner-container">
            <Spinner animation="border" />
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
      <section className="berita">
        <div className="container">
          <img src={berita.image_url} width="100%" alt={berita.title} />
          <h4>{berita.subtitle}</h4>
          <div dangerouslySetInnerHTML={{ __html: berita.content }} />

          <br />
          <hr />
          <br />
          <h3>Lihat Artikel Lainnya..</h3>
          <Berita />
         
        </div>
      </section>
        )}
      <Footer />
    </div>
  );
};

export default DetailBerita;

export const formatDate = (dateString) => {
    const options = { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('id-ID', options);
  };