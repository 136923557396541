import { Button, Carousel } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import apiClient from "../api";


export default function Testimoni() {
    const [testimoni, setTestimoni] = useState([]);

    useEffect(() => {
        const fetchTestimoni = async () => {
            try {
                const response = await apiClient.get('testimonys');
                setTestimoni(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        fetchTestimoni();
    }, []);

    return (
        <section className="testimoni" id="testimoni">
            <div className="container">
                <Carousel 
                     prevIcon={
                        <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                            style={{
                                backgroundColor: 'rgba(0, 0, 255, 0.5)',
                                borderRadius: '50%',
                                filter: 'invert(100%)',
                            }}
                        />
                    }
                    nextIcon={
                        <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                            style={{
                                backgroundColor: 'rgba(0, 0, 255, 0.5)',
                                borderRadius: '50%',
                                filter: 'invert(100%)',
                            }}
                        />
                    }
                >
                    {testimoni.map((data) => (
                        <Carousel.Item key={data.id} interval={10000}>
                            <div className="row flex-column-reverse flex-lg-row">
                                <div className="col-lg-4 text-center">
                                    <img src={data.image} alt={data.title} style={{ height: 350, width: 350, borderRadius:10 }}  />
                                    <h3 className="text-green text-center">{data.nama}</h3>
                                    <p className="text-center">Alumni Angkatan {data.angkatan}</p>

                                </div>
                                <div className="col-lg-8">
                                    <h5>Testimoni</h5>
                                    <h1>{data.title}</h1>
                                    <div dangerouslySetInnerHTML={{ __html: data.content }} />
                                    <a href="https://bit.ly/PendaftaranLPKMiraiAkademi">
                                        <Button className="btn btn-orange2">JOIN NOW</Button>
                                    </a>
                                </div>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </section>
    );
}
