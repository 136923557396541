import { useState } from 'react';
import { Button, Container } from 'react-bootstrap';

export default function NavBar() {
    const [activeNav, setActiveNav] = useState('Home');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleNavClick = (navItem) => {
        setActiveNav(navItem);
        setIsMenuOpen(false); // Tutup menu setelah memilih navigasi
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light navbar-lpk fixed-top" data-aos="fade-down">
            <Container>
                <a href="/" className="navbar-brand">
                    <img src="/images/logo.png" alt="Logo" />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleMenu}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarResponsive">
                    <ul className="navbar-nav ml-auto">
                        <li className={`nav-item ${activeNav === 'Home' ? 'active' : ''}`}>
                            <a href="/" className="nav-link" onClick={() => handleNavClick('Home')}>Home</a>
                        </li>
                        <li className={`nav-item dropdown ${activeNav === 'Profil' ? 'active' : ''}`}>
                            <a href="#" className="nav-link dropdown-toggle" id="profilDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => handleNavClick('Profil')}>
                                Profil
                            </a>
                            <div className="dropdown-menu" aria-labelledby="profilDropdown">
                                <a href="/#profil" className="dropdown-item" onClick={() => handleNavClick('Det Profil')}>Profil</a>
                                <a href="/#visi" className="dropdown-item" onClick={() => handleNavClick('Visi & Misi')}>Visi & Misi</a>
                            </div>
                        </li>
                        {/* <li className={`nav-item dropdown ${activeNav === 'Program' ? 'active' : ''}`}>
                            <a href="#" className="nav-link dropdown-toggle" id="programDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => handleNavClick('Program')}>
                                Program
                            </a>
                            <div className="dropdown-menu" aria-labelledby="programDropdown">
                                <a href="/program1" className="dropdown-item" onClick={() => handleNavClick('Program 1')}>Tokutei Ginou</a>
                                <a href="/program2" className="dropdown-item" onClick={() => handleNavClick('Program 2')}>Kuliah Sambil Kerja</a>
                            </div>
                        </li> */}
                        <li className={`nav-item ${activeNav === 'Program' ? 'active' : ''}`}>
                            <a href="/#programx" className="nav-link" onClick={() => handleNavClick('Program')}>Program</a>
                        </li>
                        <li className={`nav-item ${activeNav === 'Galeri' ? 'active' : ''}`}>
                            <a href="/#galeri" className="nav-link" onClick={() => handleNavClick('Galeri')}>Galeri</a>
                        </li>
                        <li className={`nav-item ${activeNav === 'Berita' ? 'active' : ''}`}>
                            <a href="/berita" className="nav-link" onClick={() => handleNavClick('Berita')}>Berita</a>
                        </li>
                        <li className={`nav-item ${activeNav === 'Testimoni' ? 'active' : ''}`}>
                            <a href="/#testimoni" className="nav-link" onClick={() => handleNavClick('Testimoni')}>Testimoni</a>
                        </li>
                        <li className={`nav-item ${activeNav === 'Kontak' ? 'active' : ''}`}>
                            <a href="/#kontak" className="nav-link" onClick={() => handleNavClick('Kontak')}>Kontak</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://bit.ly/PendaftaranLPKMiraiAkademi" target='_blank'><Button className='btn btn-join'>JOIN NOW</Button></a>
                        </li>
                    </ul>
                </div>

            </Container>
            <style jsx>{`
                .navbar-nav .nav-item {
                    padding: 5px 0;
                }
                .navbar-nav .nav-item .nav-link {
                    padding: 5px;
                }
                @media (max-width: 991px) {
                    .navbar-nav .nav-item .nav-link {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }

                .navbar-nav .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.navbar-nav .dropdown:hover .dropdown-menu {
    display: block;
}

.navbar-nav .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.navbar-nav .dropdown-item:hover,
.navbar-nav .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
    color: #0056b3;
}

            `}</style>
        </nav>
    );
}
